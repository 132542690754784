import { postMultipartApi, deleteApi } from "api/fetchWrapper";
import { saveNow } from "ducks/autoSaveDuck";
import { newErrorNotification } from "ducks/notificationsDuck";
import { ExternalSkjemaClient } from "../../../../../../ApiClients";

const client = new ExternalSkjemaClient();

const getFormData = (files) => {
  return files.map((file) => ({ fileName: file.name, data: file }));
};

export const lastOppVedlegg = (sporsmalId, files, callback, errorCallback) => {
  return (dispatch) => {
    saveNow()(dispatch)
      .then((e) => {
        const body = getFormData(files);

        postMultipartApi(client.lastOppSpørsmålSvarVedlegg(body, sporsmalId, e.payload.eksternId))
          .then((result) => {
            callback(result);
          })
          .catch(() => {
            errorCallback();
          });
      })
      .catch((error) => {
        dispatch(newErrorNotification(error.title, error.message));
      });
  };
};

export const slettVedlegg = (sporsmalId, eksternId, callback, errorCallback) => {
  return () => {
    deleteApi(client.slettSpørsmålSvarVedlegg(eksternId))
      .then((result) => {
        callback(result);
      })
      .catch(() => {
        errorCallback();
      });
  };
};
