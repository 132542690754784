import PropTypes from "prop-types";
import { Button } from "@utdanningsdirektoratet/button";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { EnigUenigSvarEnum } from "constants/serverEnums";
import { validationErrorsShape } from "utils/PropTypes";
import classnames from "classnames";
import { useIntl, FormattedMessage } from "react-intl";
import Obligatorisk from "./Obligatorisk";

const EnigUenigSvar = ({
  readonly,
  obligatorisk,
  forhandsvisning,
  sporsmalId,
  updateSporsmalSvar,
  enigUenigSvar,
  validationErrors,
}) => {
  const intl = useIntl();
  if (!forhandsvisning && readonly) {
    let svar = enigUenigSvar.value;
    if (svar === EnigUenigSvarEnum.IkkeAngitt) return null;
    if (svar === EnigUenigSvarEnum.HverkenEller) svar = <FormattedMessage id="formLabels.hverkenEller" />;
    if (svar === EnigUenigSvarEnum.HeltEnig) svar = <FormattedMessage id="formLabels.heltEnig" />;
    if (svar === EnigUenigSvarEnum.DelvisEnig) svar = <FormattedMessage id="formLabels.delvisEnig" />;
    if (svar === EnigUenigSvarEnum.DelvisUenig) svar = <FormattedMessage id="formLabels.delvisUenig" />;
    if (svar === EnigUenigSvarEnum.HeltUenig) svar = <FormattedMessage id="formLabels.heltUenig" />;
    return <div className="Sporsmal-body Sporsmal-body--bold">{svar}</div>;
  }
  const error = validationErrors.length > 0;
  const buttonClass = classnames({
    "Sporsmal-button": true,
    "Sporsmal-button--error": error,
  });
  const button = (val, txt) => {
    const active = enigUenigSvar.value === val;
    return (
      <Button
        className={buttonClass}
        active={active}
        disabled={readonly}
        onClick={() =>
          updateSporsmalSvar(sporsmalId, {
            enigUenigSvar: { value: active ? EnigUenigSvarEnum.IkkeAngitt : val },
          })
        }
      >
        {txt}
      </Button>
    );
  };

  const validationErrorMessagesMalform = validationErrors.map((err) => intl.formatMessage(err.text)).join("\n");

  return (
    <div className="Sporsmal-body">
      <Tooltip className="u--block" message={validationErrorMessagesMalform} position="top" align="left" disabled={!error} alwaysShow>
        <Obligatorisk isObligatorisk={obligatorisk}>
          {button(EnigUenigSvarEnum.HeltEnig, <FormattedMessage id="formLabels.heltEnig" />)}
          {button(EnigUenigSvarEnum.DelvisEnig, <FormattedMessage id="formLabels.delvisEnig" />)}
          {button(EnigUenigSvarEnum.HverkenEller, <FormattedMessage id="formLabels.hverkenEller" />)}
          {button(EnigUenigSvarEnum.DelvisUenig, <FormattedMessage id="formLabels.delvisUenig" />)}
          {button(EnigUenigSvarEnum.HeltUenig, <FormattedMessage id="formLabels.heltUenig" />)}
        </Obligatorisk>
      </Tooltip>
    </div>
  );
};

EnigUenigSvar.propTypes = {
  sporsmalId: PropTypes.number.isRequired,
  updateSporsmalSvar: PropTypes.func.isRequired,
  enigUenigSvar: PropTypes.shape({
    value: PropTypes.oneOf([
      EnigUenigSvarEnum.IkkeAngitt,
      EnigUenigSvarEnum.HeltUenig,
      EnigUenigSvarEnum.DelvisUenig,
      EnigUenigSvarEnum.HverkenEller,
      EnigUenigSvarEnum.DelvisEnig,
      EnigUenigSvarEnum.HeltEnig,
    ]).isRequired,
  }).isRequired,
  readonly: PropTypes.bool.isRequired,
  obligatorisk: PropTypes.bool,
  forhandsvisning: PropTypes.bool.isRequired,
  validationErrors: validationErrorsShape.isRequired,
};

export default EnigUenigSvar;
