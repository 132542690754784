import PropTypes from "prop-types";

export const ingenMerknaderShape = PropTypes.shape({
  enabled: PropTypes.bool,
  svar: PropTypes.bool,
});

export const malformStringShape = PropTypes.shape({
  nb: PropTypes.string,
  nn: PropTypes.string,
});

export const innsenderShape = PropTypes.shape({
  epost: PropTypes.string,
  navn: PropTypes.string,
});

export const undertypeShape = PropTypes.shape({
  erAktiv: PropTypes.bool,
  id: PropTypes.number.isRequired,
  rekkefolge: PropTypes.number,
  tittel: PropTypes.string.isRequired,
});

export const malgruppeShape = PropTypes.shape({
  bekreftRepresentasjon: PropTypes.bool,
  bekreftRepresentasjonFeilMelding: PropTypes.object,
  bekreftRepresentasjonTekst: PropTypes.object,
  geografiObligatoriskhet: PropTypes.string,
  id: PropTypes.number.isRequired,
  innsenderRepresentererEtikett: PropTypes.string,
  innsenderRepresentererObligatoriskhet: PropTypes.string,
  innsenderStillingObligatoriskhet: PropTypes.string,
  kanHaFlereUndertyper: PropTypes.bool,
  sektor: PropTypes.string,
  tittel: PropTypes.string.isRequired,
  undertypeErObligatorisk: PropTypes.bool,
  undertyper: PropTypes.arrayOf(undertypeShape),
});

export const jaNeiShape = PropTypes.shape({
  obligatorisk: PropTypes.bool,
  svar: PropTypes.string,
});

export const enigUenigShape = PropTypes.shape({
  obligatorisk: PropTypes.bool,
  svar: PropTypes.string,
});

export const svarAlternativShape = PropTypes.shape({
  erEgendefinert: PropTypes.bool,
  id: PropTypes.number.isRequired,
  rekkefolge: PropTypes.number,
  selected: PropTypes.bool,
  tittel: malformStringShape,
});

export const kommentarShape = PropTypes.shape({
  obligatorisk: PropTypes.bool,
  svar: PropTypes.string,
});

export const vedleggShape = PropTypes.shape({
  filNavn: PropTypes.string.isRequired,
  mimeType: PropTypes.string,
  url: PropTypes.string.isRequired,
});

export const svarVedleggShape = PropTypes.shape({
  obligatorisk: PropTypes.bool,
  vedlegg: PropTypes.arrayOf(vedleggShape),
});

export const sporsmalShape = PropTypes.shape({
  brodTekst: malformStringShape,
  erHovedSporsmal: PropTypes.bool,
  id: PropTypes.number.isRequired,
  jaNei: jaNeiShape,
  enigUenig: enigUenigShape,
  ettValg: PropTypes.object,
  kommentar: kommentarShape,
  rekkefolge: PropTypes.number,
  tittel: malformStringShape,
  vedlegg: svarVedleggShape,
});

export const markeringKommentarShape = PropTypes.shape({
  id: PropTypes.number,
  tag: PropTypes.string.isRequired,
  kommentar: PropTypes.string,
  startIndex: PropTypes.number.isRequired,
  endIndex: PropTypes.number.isRequired,
  hash: PropTypes.number.isRequired,
  bildeId: PropTypes.string,
  seksjonId: PropTypes.number.isRequired,
});

export const seksjonShape = PropTypes.shape({
  brodtekst: malformStringShape,
  id: PropTypes.number.isRequired,
  kanHaMarkering: PropTypes.bool.isRequired,
  niva: PropTypes.number,
  nummerering: PropTypes.string.isRequired,
  rekkefolge: PropTypes.number,
  sporsmals: PropTypes.arrayOf(sporsmalShape),
  tittel: malformStringShape,
  markeringKommentarer: PropTypes.arrayOf(markeringKommentarShape),
});

export const signaturShape = PropTypes.shape({
  footer: malformStringShape,
  hilsen: malformStringShape,
  signaturNavn1: PropTypes.string,
  signaturNavn2: PropTypes.string,
  signaturStilling1: malformStringShape,
  signaturStilling2: malformStringShape,
  divisjonsdirektør: PropTypes.string,
});

export const underskjemaSvarShape = PropTypes.shape({
  generellKommentar: PropTypes.string,
  generellHoldning: PropTypes.string,
});

export const underskjemaShape = PropTypes.shape({
  avsender: PropTypes.string,
  id: PropTypes.number.isRequired,
  menyTittel: malformStringShape,
  rekkefolge: PropTypes.number,
  saksNr: PropTypes.string,
  seksjons: PropTypes.arrayOf(seksjonShape),
  signatur: signaturShape,
  tittel: malformStringShape,
  underskjemaSvar: underskjemaSvarShape,
  vedlegg: PropTypes.arrayOf(vedleggShape),
  kanHaGenerellHoldning: PropTypes.bool,
  kanHaGenerellKommentar: PropTypes.bool,
});

export const skjemaShape = PropTypes.shape({
  bekreftetStatus: PropTypes.string.isRequired,
  eksternId: PropTypes.string.isRequired,
  frist: PropTypes.string,
  skjemaId: PropTypes.number.isRequired,
  ingenMerknader: ingenMerknaderShape,
  innsender: innsenderShape.isRequired,
  sendInnKunSisteUnderskjema: PropTypes.bool,
  innsendingsKode: PropTypes.string,
  malgrupper: PropTypes.arrayOf(malgruppeShape).isRequired,
  mottatt: PropTypes.string,
  underskjema: PropTypes.arrayOf(underskjemaShape),
  publiseringsStatus: PropTypes.string.isRequired,
  publisert: PropTypes.string,
  readOnly: PropTypes.bool,
  showTutorialOverlay: PropTypes.bool,
  svarBekreftelse: PropTypes.string.isRequired,
  tittel: malformStringShape,
  vedlegg: PropTypes.arrayOf(vedleggShape),
});

export const notificationShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  titleKey: PropTypes.string.isRequired,
  messageKey: PropTypes.string,
  type: PropTypes.string.isRequired,
});

export const validationErrorsShape = PropTypes.arrayOf(
  PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  })
);
