import skjema from 'ducks/skjemaDuck';
import skjemaSvar from 'ducks/skjemaSvarDuck';
import autoSave from 'ducks/autoSaveDuck';
import notifications from 'ducks/notificationsDuck';
import validation from 'ducks/validationDuck';
import navigation from 'ducks/navigationDuck';
import locale from 'ducks/localeDuck';

const trivialReducer = (state = {}) => state;

export default {
  skjemaSvar,
  initialSkjemaSvar: trivialReducer,
  autoSave,
  notifications,
  skjema,
  validation,
  navigation,
  fylker: trivialReducer,
  texts: trivialReducer,
  sporsmal: trivialReducer,
  seksjon: trivialReducer,
  underskjema: trivialReducer,
  locale,
  footer: trivialReducer,
  institusjonLongName: trivialReducer,
  personvernerklaering: trivialReducer,
  page: trivialReducer,
  enums: trivialReducer
};
