import classnames from "classnames";
import PropTypes from "prop-types";
import { Button, LinkButton } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { FormattedMessage, useIntl } from "react-intl";

const LagreOgSendInn = ({
  actions,
  innsendt,
  underskjemaHasSporsmals,
  forhandsvisning,
  hasFeedback,
  readOnly,
  nextUnderskjema,
  currentUnderskjema,
  sendInnKunSisteUnderskjema,
}) => {
  const intl = useIntl();
  if (innsendt || (readOnly && !forhandsvisning)) return null;

  const lagreButton =
    nextUnderskjema.id !== currentUnderskjema.id ? (
      <Button
        className="Button--colored FooterButtons-button FooterButtons-submit"
        disabled={readOnly}
        onClick={() => actions.lagreOgGaVidere(currentUnderskjema.id, nextUnderskjema.id)}
      >
        <FormattedMessage id="formLabels.lagreOgGaVidere" />
        <Icon type="wide" icon="arrow right long" placement="after" />
      </Button>
    ) : (
      <Button
        color="vy"
        className="Button--colored FooterButtons-button FooterButtons-submit"
        disabled={!hasFeedback || readOnly}
        onClick={actions.showLagreOgSendInn}
      >
        <FormattedMessage id="formLabels.sendInn" />
      </Button>
    );

  const buttonClass = classnames({
    "Grid-xl-4-12": underskjemaHasSporsmals,
    "Grid-md-6-12": underskjemaHasSporsmals,
    "Grid-12-12": underskjemaHasSporsmals,
    "FooterButtons-next": underskjemaHasSporsmals,
  });

  const reset = new URLSearchParams(window.location.search);
  reset.delete("utkast");
  reset.set("reset", "true");

  return (
    <div className="Page-section Page-section--narrow FooterButtons">
      {underskjemaHasSporsmals ? (
        <div className="Grid-xl-8-12 Grid-md-6-12 Grid-12-12">
          {lagreButton}
          <Button className="FooterButtons-button" disabled={!hasFeedback || readOnly} onClick={actions.showLagreUtkast}>
            <FormattedMessage id="formLabels.lagreUtkast" />
          </Button>
          <Button className="FooterButtons-button" disabled={!hasFeedback || readOnly} onClick={actions.showVideresendUtkast}>
            <FormattedMessage id="formLabels.videresendUtkast" />
          </Button>
          <Tooltip
            disabled={!hasFeedback || readOnly}
            className="u--inlineBlock"
            message={intl.formatMessage({ id: "formLabels.nyttUtkast" })}
          >
            <LinkButton
              href={`?${reset.toString()}`}
              aria-label={intl.formatMessage({ id: "formLabels.nyttUtkast" })}
              className="FooterButtons-button"
              disabled={!hasFeedback || readOnly}
            >
              <Icon type="small" icon="plus" />
            </LinkButton>
          </Tooltip>
        </div>
      ) : null}
      <div className={buttonClass}>
        {!sendInnKunSisteUnderskjema && nextUnderskjema.id !== currentUnderskjema.id ? (
          <Button className="FooterButtons-button" onClick={() => actions.navigateToUnderskjema(nextUnderskjema.id)}>
            <FormattedMessage id="formLabels.nesteUnderskjema" />
            <Icon type="wide" icon="arrow right long" placement="after" />
          </Button>
        ) : null}
      </div>
    </div>
  );
};

LagreOgSendInn.propTypes = {
  actions: PropTypes.object.isRequired,
  hasFeedback: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  innsendt: PropTypes.bool.isRequired,
  forhandsvisning: PropTypes.bool.isRequired,
  nextUnderskjema: PropTypes.object,
  currentUnderskjema: PropTypes.object,
  sendInnKunSisteUnderskjema: PropTypes.bool,
  underskjemaHasSporsmals: PropTypes.bool,
};

export default LagreOgSendInn;
