import { COMPLETE_AUTOSAVE } from './autoSaveDuck';

const defaultState = {};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case COMPLETE_AUTOSAVE:
      const { endret, eksternId } = action.payload;
      return { ...state, endret, eksternId };
    default:
      return state;
  }
};

export const getUnderskjema = (underskjemaId, skjema) => {
  return skjema.underskjema.find((n) => n.id === underskjemaId) || skjema.underskjema[0];
};

export const getNextUnderskjema = (underskjemaId, skjema) => {
  const underskjema = getUnderskjema(underskjemaId, skjema);
  const index = skjema.underskjema.indexOf(underskjema);
  if (index >= 0 && index < skjema.underskjema.length - 1) {
    return skjema.underskjema[index + 1];
  }
  return underskjema;
};

export default reducer;
