import PropTypes from "prop-types";
import { Popin } from "@utdanningsdirektoratet/popin";
import { Input } from "@utdanningsdirektoratet/input";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormValidation, validationFunctions } from "@utdanningsdirektoratet/form";

import { ExternalSkjemaClient } from "../../../ApiClients";
import { epostValidation } from "../../../logic/validation";

const client = new ExternalSkjemaClient();

const RedigerEpostPopin = ({ id, eksternId, epost }) => {
  const intl = useIntl();

  const { fields, values, isValid } = useFormValidation({
    epost: {
      value: epost,
      validation: [
        { ...validationFunctions.required, message: intl.formatMessage({ id: "validation.epostErObligatorisk" }) },
        { ...epostValidation, message: intl.formatMessage(epostValidation.message) },
      ],
    },
  });

  const editEmail = () => {
    client
      .endreEpost(eksternId, values.epost)
      .then(() => {
        window.location.reload();
      })
      .catch(() => null);
  };

  return (
    <Popin
      id={id}
      title={<FormattedMessage id="innsendtSvar.endreEpostAdresse" />}
      onSave={() => {
        if (isValid) {
          editEmail();
        }
      }}
      saveButton={<FormattedMessage id="innsendtSvar.lagreOgSend" />}
      cancelButton={<FormattedMessage id="formLabels.avbryt" />}
    >
      <Input
        {...fields.epost}
        className="u--marginBottom2"
        type="email"
        label={intl.formatMessage({ id: "innsendtSvar.epostadresse" })}
      />
    </Popin>
  );
};

RedigerEpostPopin.propTypes = {
  id: PropTypes.string,
  eksternId: PropTypes.string,
  epost: PropTypes.string,
};

export default RedigerEpostPopin;
