import PropTypes from "prop-types";
import { Element } from "react-scroll";
import { underskjemaShape } from "utils/PropTypes";
import { connect } from "react-redux";
import { getUnderskjemaSvar } from "ducks/skjemaSvarDuck";
import { getValidation, filterByType } from "ducks/validationDuck.js";
import { svarTypes } from "constants/svarTypes";
import { scrollToId, ScrollType } from "utils/scrollUtils";

import PositivNegativSvar from "./components/PositivNegativ";
import Kommentar from "./components/Kommentar";

const UnderskjemaSvar = (props) => {
  const { forhandsvisning, validation, underskjema, underskjemaSvar, ingenMerknader, updateUnderskjemaSvar } = props;
  const { kanHaGenerellHoldning, kanHaGenerellKommentar, id } = underskjema;

  if (!kanHaGenerellHoldning && !kanHaGenerellKommentar) return null;

  const readonly = props.readonly && ingenMerknader;

  return (
    <div key={`underskjemasvar-${id}`} className="Underskjemasvar">
      <div className="Sporsmal" id={`underskjema_${id}`}>
        <Element name={scrollToId(ScrollType.UNDERSKJEMA, id)} />
        <div>
          {kanHaGenerellHoldning ? (
            <PositivNegativSvar
              id={id}
              updateSvar={updateUnderskjemaSvar}
              positivNegativSvar={underskjemaSvar.positivNegativSvar}
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              validationErrors={filterByType(svarTypes.POSITIV_NEGATIV, validation)}
              generell
            />
          ) : null}

          {kanHaGenerellKommentar ? (
            <Kommentar
              readonly={readonly}
              forhandsvisning={forhandsvisning}
              kommentar={underskjemaSvar.kommentar}
              id={id}
              updateSvar={updateUnderskjemaSvar}
              validationErrors={filterByType(svarTypes.KOMMENTAR, validation)}
              generell
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

UnderskjemaSvar.propTypes = {
  underskjema: underskjemaShape.isRequired,
  updateUnderskjemaSvar: PropTypes.func.isRequired,
  underskjemaSvar: PropTypes.object.isRequired,
  validation: PropTypes.array.isRequired,
  readonly: PropTypes.bool.isRequired,
  ingenMerknader: PropTypes.bool.isRequired,
  forhandsvisning: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const underskjemaId = ownProps.underskjema.id;
  return {
    ingenMerknader: state.skjemaSvar.ingenMerknader,
    underskjemaSvar: getUnderskjemaSvar(underskjemaId, state.skjemaSvar),
    validation: getValidation(underskjemaId, state.validation.underskjema),
  };
};

export default connect(mapStateToProps)(UnderskjemaSvar);
