import {
  validateSporsmal,
  validateSeksjon,
  validateUnderskjema,
  isSporsmalBesvart,
  isSeksjonBesvart,
  isUnderskjemaBesvart,
} from "logic/validation";
import {
  UPDATE_SPORSMAL_SVAR,
  UPDATE_SEKSJON_SVAR,
  UPDATE_UNDERSKJEMA_SVAR,
  getSporsmalSvar,
  getSeksjonSvar,
  getUnderskjemaSvar,
} from "./skjemaSvarDuck";

const VALIDATE_ALL = "validation/ALL";
const VALIDATE_UNDERSKJEMA = "validation/UNDERSKJEMA";
const VALIDATE_OPPGITT = "validation/OPPGITT";

const filterAndReduce = (validations) =>
  validations.filter((u) => u && u.errors && u.errors.length > 0).reduce((st, u) => ({ ...st, [u.id]: u.errors }), {});

const validateSvar = (svars, validate, getSvar, skjemaSvar) =>
  Object.entries(svars).map(([id, svar]) => ({ id, errors: validate(svar, getSvar(id, skjemaSvar)) }));
const validateOppgittSvar = (oppgittSvar, isBesvart, validate, eksisterendeSvar) =>
  Object.entries(oppgittSvar)
    .filter(([, svar]) => isBesvart(svar))
    .map(([id, svar]) => ({ id, errors: validate(eksisterendeSvar[id], svar) }));

const reducer = (state = {}, action) => {
  const pl = action.payload;
  switch (action.type) {
    case UPDATE_SPORSMAL_SVAR:
      return {
        ...state,
        sporsmal: { ...state.sporsmal, [pl.sporsmalId]: validateSporsmal(pl.sporsmal, { ...pl.oldState, ...pl.sporsmalSvar }) },
      };

    case UPDATE_SEKSJON_SVAR:
      return {
        ...state,
        seksjoner: { ...state.seksjoner, [pl.seksjonId]: validateSporsmal(pl.seksjon, { ...pl.oldState, ...pl.seksjonSvar }) },
      };

    case UPDATE_UNDERSKJEMA_SVAR:
      return {
        ...state,
        underskjema: {
          ...state.underskjema,
          [pl.underskjemaId]: validateSporsmal(pl.underskjema, { ...pl.oldState, ...pl.underskjemaSvar }),
        },
      };

    case VALIDATE_ALL:
      return {
        ...state,
        sporsmal: filterAndReduce(validateSvar(pl.sporsmal, validateSporsmal, getSporsmalSvar, pl.skjemaSvar)),
        seksjoner: filterAndReduce(validateSvar(pl.seksjoner, validateSeksjon, getSeksjonSvar, pl.skjemaSvar)),
        underskjema: filterAndReduce(validateSvar(pl.underskjema, validateUnderskjema, getUnderskjemaSvar, pl.skjemaSvar)),
      };

    case VALIDATE_UNDERSKJEMA:
      const currentUnderskjema = { [pl.id]: pl.underskjema[pl.id] };
      const currentSeksjons = Object.values(pl.seksjoner)
        .filter((s) => s.underskjemaId === pl.id)
        .reduce((val, curr) => ({ [curr.id]: curr, ...val }), {});
      const currentSporsmals = Object.values(pl.sporsmal)
        .filter((s) => s.underskjemaId === pl.id)
        .reduce((val, curr) => ({ [curr.id]: curr, ...val }), {});
      return {
        ...state,
        sporsmal: filterAndReduce(validateSvar(currentSporsmals, validateSporsmal, getSporsmalSvar, pl.skjemaSvar)),
        seksjoner: filterAndReduce(validateSvar(currentSeksjons, validateSeksjon, getSeksjonSvar, pl.skjemaSvar)),
        underskjema: filterAndReduce(validateSvar(currentUnderskjema, validateUnderskjema, getUnderskjemaSvar, pl.skjemaSvar)),
      };

    case VALIDATE_OPPGITT:
      return {
        ...state,
        sporsmal: filterAndReduce(validateOppgittSvar(pl.skjemaSvar.sporsmal, isSporsmalBesvart, validateSporsmal, pl.sporsmal)),
        seksjoner: filterAndReduce(validateOppgittSvar(pl.skjemaSvar.seksjoner, isSeksjonBesvart, validateSeksjon, pl.seksjoner)),
        underskjema: filterAndReduce(
          validateOppgittSvar(pl.skjemaSvar.underskjema, isUnderskjemaBesvart, validateUnderskjema, pl.underskjema)
        ),
      };
    default:
      return state;
  }
};

export const getValidation = (id, state) => state[id] || [];
export const firstInvalidId = (state) => {
  const result = {};
  Object.keys(state).forEach((key) => {
    result[key] = Object.keys(state[key])
      .map((id) => ({ id, errors: getValidation(id, state[key]) }))
      .filter((v) => v.errors.length > 0)
      .map(({ id }) => parseInt(id, 10))
      .sort((a, b) => a - b)
      .find((id) => id);
  });
  return result;
};

export const filterByType = (type, validation = []) => validation.filter((v) => v.type === type);
const dispatchSkjemaSvar = (type) => (id) => (dispatch, getState) =>
  dispatch({
    type,
    payload: {
      id,
      skjemaSvar: getState().skjemaSvar,
      sporsmal: getState().sporsmal,
      seksjoner: getState().seksjon,
      underskjema: getState().underskjema,
    },
  });
export const validateAll = dispatchSkjemaSvar(VALIDATE_ALL);
export const validateOppgittUnderskjema = dispatchSkjemaSvar(VALIDATE_UNDERSKJEMA);
export const validateOppgitt = dispatchSkjemaSvar(VALIDATE_OPPGITT);

export default reducer;
