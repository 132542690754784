import "less/base.less";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { ConnectedRouter } from "connected-react-router";
import * as Sentry from "@sentry/react";
import initialState, { sentry } from "./logic/initialState";

import configureStore from "./configureStore";

import App from "./App";

const history = createBrowserHistory();
const store = configureStore(initialState(), history);

Sentry.init({
  dsn: sentry,
  integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
  tracesSampleRate: 0,
  normalizeDepth: 10,
  release: `skjema@${process.env.COMMIT_HASH}`,
});

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById("app") // eslint-disable-line
);
