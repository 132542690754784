import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Input } from "@utdanningsdirektoratet/input";
import { Radio } from "@utdanningsdirektoratet/radio";
import { Form, useFormValidation, validationFunctions } from "@utdanningsdirektoratet/form";
import { Popin, PopinFooter, close } from "@utdanningsdirektoratet/popin";
import { downloadFile } from "utils/urlUtils";
import { skjemaShape } from "utils/PropTypes";
import { urls } from "logic/initialState";
import { epostValidation } from "logic/validation";
import * as serverActions from "./serverActions";

const RadioTypes = {
  EPOST: "EPOST",
  LAST_NED: "LAST_NED",
};

const LagreUtkastPopin = (props) => {
  const { id, eksternId, skjema, skjemaSvar, actions, ...rest } = props;
  const intl = useIntl();

  const formValidation = useFormValidation({
    innsenderEpost: {
      value: "",
      validation: [
        {
          ...validationFunctions.requiredIf(validationFunctions.required, (_, values) => values.type === RadioTypes.EPOST),
          message: intl.formatMessage({ id: "validation.epostErObligatorisk" }),
        },
        {
          ...validationFunctions.requiredIf(epostValidation, (_, values) => values.type === RadioTypes.EPOST),
          message: intl.formatMessage(epostValidation.message),
        },
      ],
    },
    type: { value: RadioTypes.EPOST, validation: [validationFunctions.required] },
  });
  const { fields, values } = formValidation;
  const onSuccess = () => {
    if (values.type === RadioTypes.EPOST) {
      actions.lagreUtkast({ ...values, innsendingsKode: skjema.innsendingsKode }, () => {
        close(id);
      });
    } else if (values.type === RadioTypes.LAST_NED) {
      downloadFile(`${urls.lastNedUtkastUrl}/${eksternId}`, "get", { innsendingsKode: skjema.innsendingsKode });
    }
  };

  return (
    <Popin
      className="LagreUtkastPopin"
      id={id}
      title={<FormattedMessage id="lagreUtkastPopin.title" />}
      closeableByExternalEvents
      cancelButton={false}
    >
      <Form {...rest} {...formValidation} onSubmit={onSuccess}>
        {({ onSubmit }) => {
          return (
            <div>
              <p>
                <FormattedMessage id="lagreUtkastPopin.body" />
              </p>
              <Radio
                label={<FormattedMessage id="lagreUtkastPopin.saveDraftSendEpost" />}
                name="lagreUtkast"
                checked={fields.type.value === RadioTypes.EPOST}
                onChange={() => fields.type.onChange(RadioTypes.EPOST)}
                error={fields.type.error}
              >
                <p>
                  <FormattedMessage id="lagreUtkastPopin.saveDraftLastNedText" />
                </p>
                <Input
                  {...fields.innsenderEpost}
                  type="email"
                  autoComplete="email"
                  label={<FormattedMessage id="lagreUtkastPopin.epost" />}
                />
              </Radio>
              <Radio
                label={<FormattedMessage id="lagreUtkastPopin.saveDraftLastNed" />}
                name="lagreUtkast"
                checked={fields.type.value === RadioTypes.LAST_NED}
                onChange={() => fields.type.onChange(RadioTypes.LAST_NED)}
                error={fields.type.error}
                boxed
              />
              <PopinFooter
                popinId={id}
                onSave={onSubmit}
                saveButton={<FormattedMessage id="formLabels.lagre" />}
                cancelButton={<FormattedMessage id="formLabels.avbryt" />}
              />
            </div>
          );
        }}
      </Form>
    </Popin>
  );
};

LagreUtkastPopin.propTypes = {
  id: PropTypes.string.isRequired,
  skjema: skjemaShape.isRequired,
  skjemaSvar: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  eksternId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    skjema: state.skjema,
    skjemaSvar: state.skjemaSvar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(serverActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LagreUtkastPopin);
