import { isSeksjonBesvart } from 'logic/validation';

export default sporsmalSvar => Object.keys(sporsmalSvar)
  .map(id => ({ id, utt: sporsmalSvar[id] }))
  .filter(u => isSeksjonBesvart(u.utt))
  .map(u => ({
    id: u.id,
    markeringKommentarer: u.utt.markeringKommentarer
      ? u.utt.markeringKommentarer
      : undefined
  }));
