import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import { DateInput } from "@utdanningsdirektoratet/dateinput";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { DATE_FORMAT } from "constants/dateFormats";
import { validationErrorsShape } from "utils/PropTypes";
import { useIntl, FormattedMessage } from "react-intl";
import Obligatorisk from "./Obligatorisk";

const onChangeDebounced = debounce((value, dates, updateSporsmalSvar, sporsmalId) => {
  updateSporsmalSvar(sporsmalId, {
    datoSvar: { value: value && value.toISOString(), dates },
  });
}, 3000);

const onChange = (value, dates, updateSporsmalSvar, sporsmalId) => {
  onChangeDebounced(value, dates, updateSporsmalSvar, sporsmalId);
  if (value) {
    updateSporsmalSvar(sporsmalId, {
      datoSvar: { value: value.toISOString(), dates },
    });
  }
};

const DatoSvar = ({ readonly, obligatorisk, sporsmalId, updateSporsmalSvar, forhandsvisning, datoSvar, validationErrors }) => {
  const intl = useIntl();
  const error = validationErrors.length > 0;
  const validationErrorMessagesMalform = validationErrors.map((err) => intl.formatMessage(err.text)).join("\n");

  if (!forhandsvisning && readonly) {
    if (datoSvar.value) {
      return <div className="Sporsmal-body">{formatDate(datoSvar.value, DATE_FORMAT)}</div>;
    }
    return (
      <div className="Sporsmal-body Sporsmal-body--italic">
        <FormattedMessage id="validation.ikkeAngittDato" />
      </div>
    );
  }
  return (
    <div className="Sporsmal-body">
      <Obligatorisk isObligatorisk={obligatorisk}>
        <DateInput
          className="Sporsmal-dato"
          error={error}
          message={validationErrorMessagesMalform}
          disabled={readonly}
          value={datoSvar.value}
          onChange={(value, dates) => onChange(value, dates, updateSporsmalSvar, sporsmalId)}
        />
      </Obligatorisk>
    </div>
  );
};

DatoSvar.propTypes = {
  sporsmalId: PropTypes.number.isRequired,
  updateSporsmalSvar: PropTypes.func.isRequired,
  datoSvar: PropTypes.shape({
    value: PropTypes.date,
  }).isRequired,
  readonly: PropTypes.bool.isRequired,
  obligatorisk: PropTypes.bool,
  forhandsvisning: PropTypes.bool.isRequired,
  validationErrors: validationErrorsShape.isRequired,
};

export default DatoSvar;
