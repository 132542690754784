import { postApi } from 'api/fetchWrapper';
import { newSuccessNotification, newErrorNotification } from 'ducks/notificationsDuck';
import { saveNow } from 'ducks/autoSaveDuck';
import { ExternalSkjemaClient } from '../../../../ApiClients';

const client = new ExternalSkjemaClient();

export const lagreUtkast = (values, onSuccess) => {
  return (dispatch) => {
    saveNow()(dispatch)
      .then((e) => {
        postApi(client.lagreUtkast(
          e.payload.eksternId,
          values.innsendingsKode,
          values.innsenderEpost
        ))
          .then((result) => {
            onSuccess();
            dispatch(newSuccessNotification(result.title, result.message, true));
          })
          .catch((error) => {
            dispatch(newErrorNotification(error.title, error.message));
          });
      })
      .catch((error) => {
        dispatch(newErrorNotification(error.title, error.message));
      });
  };
};
