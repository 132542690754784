import { isSporsmalBesvart, isSeksjonBesvart, isUnderskjemaBesvart } from "logic/validation";
import { registerActionForAutoSave, UPDATE_SPORSMAL_SVAR_INIT } from "./autoSaveDuck";

const SET_INGEN_MERKNADER = "skjemaSvar/SET_INGEN_MERKNADER";

export const UPDATE_SPORSMAL_SVAR = "skjemaSvar/UPDATE_SPORSMAL_SVAR";
export const UPDATE_SEKSJON_SVAR = "skjemaSvar/UPDATE_SEKSJON_SVAR";
export const UPDATE_UNDERSKJEMA_SVAR = "skjemaSvar/UPDATE_UNDERSKJEMA_SVAR";

const hasFeedback = (skjemaSvar, isBesvart) =>
  skjemaSvar && Object.values(skjemaSvar).filter((skjemaSvar) => isBesvart(skjemaSvar)).length > 0;

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_INGEN_MERKNADER:
      return { ...state, ingenMerknader: action.payload };

    case UPDATE_SPORSMAL_SVAR_INIT:
    case UPDATE_SPORSMAL_SVAR:
      return {
        ...state,
        sporsmal: {
          ...state.sporsmal,
          [action.payload.sporsmalId]: { ...state.sporsmal[action.payload.sporsmalId], ...action.payload.sporsmalSvar },
        },
      };

    case UPDATE_SEKSJON_SVAR:
      return {
        ...state,
        seksjoner: {
          ...state.seksjoner,
          [action.payload.seksjonId]: { ...state.seksjoner[action.payload.seksjonId], ...action.payload.seksjonSvar },
        },
      };

    case UPDATE_UNDERSKJEMA_SVAR:
      return {
        ...state,
        underskjema: {
          ...state.underskjema,
          [action.payload.underskjemaId]: { ...state.underskjema[action.payload.underskjemaId], ...action.payload.underskjemaSvar },
        },
      };

    default:
      return {
        ...state,
        hasFeedback:
          hasFeedback(state.sporsmal, isSporsmalBesvart) ||
          hasFeedback(state.seksjoner, isSeksjonBesvart) ||
          hasFeedback(state.underskjema, isUnderskjemaBesvart),
      };
  }
};

// actions

export const setIngenMerknader = (value) => {
  return {
    type: SET_INGEN_MERKNADER,
    payload: value,
  };
};

export const updateSporsmalSvar = (sporsmalId, sporsmalSvar, sporsmal, oldState) => {
  return {
    type: UPDATE_SPORSMAL_SVAR,
    payload: {
      sporsmalId,
      sporsmalSvar,
      sporsmal,
      oldState,
    },
  };
};

export const updateSporsmalSvarInit = (sporsmalId, sporsmalSvar, sporsmal, oldState) => {
  return {
    type: UPDATE_SPORSMAL_SVAR_INIT,
    payload: {
      sporsmalId,
      sporsmalSvar,
      sporsmal,
      oldState,
    },
  };
};

export const updateSeksjonSvar = (seksjonId, seksjonSvar, seksjon, oldState) => {
  return {
    type: UPDATE_SEKSJON_SVAR,
    payload: {
      seksjonId,
      seksjonSvar,
      seksjon,
      oldState,
    },
  };
};

export const updateUnderskjemaSvar = (underskjemaId, underskjemaSvar, underskjema, oldState) => {
  return {
    type: UPDATE_UNDERSKJEMA_SVAR,
    payload: {
      underskjemaId,
      underskjemaSvar,
      underskjema,
      oldState,
    },
  };
};

// selectors
export const getSporsmalSvar = (sporsmalId, skjemaSvar) => {
  return skjemaSvar.sporsmal[sporsmalId];
};

export const getSeksjonSvar = (seksjonId, skjemaSvar) => {
  return skjemaSvar.seksjoner[seksjonId];
};

export const getUnderskjemaSvar = (underskjemaId, skjemaSvar) => {
  return skjemaSvar.underskjema[underskjemaId];
};

registerActionForAutoSave(UPDATE_SPORSMAL_SVAR);
registerActionForAutoSave(UPDATE_SEKSJON_SVAR);
registerActionForAutoSave(UPDATE_UNDERSKJEMA_SVAR);

export default reducer;
