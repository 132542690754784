import PropTypes from "prop-types";
import classnames from "classnames";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import { Icon } from "@utdanningsdirektoratet/icon";
import ReactRouterPropTypes from "react-router-prop-types";
import { TIMESTAMP_FORMAT } from "constants/dateFormats";
import { FormattedMessage } from "react-intl";
import { underskjemaShape } from "utils/PropTypes";
import UnderskjemaLink from "./UnderskjemaLink";

const getUnderskjemaLink = (currentUnderskjema, underskjema, location, ingenMerkander) => {
  const selected = currentUnderskjema.id === underskjema.id;
  const itemClass = classnames({
    "Sidebar-item": true,
    "Sidebar-item--selected": selected,
  });
  return (
    <div className={itemClass} key={`sidebar-item-${underskjema.id}`}>
      <UnderskjemaLink location={location} underskjema={underskjema} ingenMerkander={ingenMerkander} />
      <Icon className="Sidebar-item-icon" type="small" icon="angle right" />
    </div>
  );
};

const Sidebar = ({ currentUnderskjema, underskjema, location, endret, innsendt, showTimestamp }) => {
  const body = underskjema
    .filter((n) => !innsendt || n.underskjemaSvar.hasFeedback || n.rekkefolge === 0)
    .map((underskjema) => getUnderskjemaLink(currentUnderskjema, underskjema, location));

  if (body.length <= 1) return null;

  const underskjemaWithoutFeedback = underskjema
    .filter((n) => innsendt && !n.underskjemaSvar.hasFeedback && n.rekkefolge !== 0)
    .map((underskjema) => getUnderskjemaLink(currentUnderskjema, underskjema, location, true));

  const timestampClass = classnames({
    "Sidebar-timestamp": true,
    "Sidebar-timestamp--visible": showTimestamp && endret,
  });

  return (
    <nav className="Sidebar">
      <div className="Sidebar-wrapper">{body}</div>

      {innsendt && underskjemaWithoutFeedback.length > 0 ? (
        <div className="Sidebar-wrapper Sidebar-wrapper--faded">{underskjemaWithoutFeedback}</div>
      ) : null}

      <div className={timestampClass}>
        <FormattedMessage id="svar.sistLagret" />: {endret ? formatDate(endret, TIMESTAMP_FORMAT) : null}
      </div>
    </nav>
  );
};

Sidebar.propTypes = {
  currentUnderskjema: underskjemaShape.isRequired,
  underskjema: PropTypes.arrayOf(underskjemaShape).isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  endret: PropTypes.string,
  innsendt: PropTypes.bool,
  showTimestamp: PropTypes.bool,
};

export default Sidebar;
