import { arrayRemove, arrayUpdate } from 'utils/immutable';

export const localstorageAvailable = () => {
  try {
    const storage = window.localStorage || global.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    if (storage.getItem(x) !== x) {
      return false;
    }
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

export const getUtkasts = (skjemaId) => {
  if (localstorageAvailable()) {
    return JSON.parse(localStorage.getItem(skjemaId)) || [];
  }
  return [];
};

export const addOrUpdateUtkast = (skjemaId, utkast) => {
  if (localstorageAvailable()) {
    const utkasts = getUtkasts(skjemaId);
    const index = utkasts.findIndex(u => u.eksternId === utkast.eksternId);
    if (index !== -1) {
      localStorage.setItem(skjemaId, JSON.stringify(arrayUpdate(utkasts, index, utkast)));
    } else {
      localStorage.setItem(skjemaId, JSON.stringify([...utkasts, utkast]));
    }
  }
};

export const removeUtkast = (skjemaId, eksternId) => {
  if (localstorageAvailable()) {
    const utkasts = getUtkasts(skjemaId);
    const index = utkasts.findIndex(u => u.eksternId === eksternId);
    if (index !== -1) {
      localStorage.setItem(skjemaId, JSON.stringify(arrayRemove(utkasts, index)));
    }
  }
};
