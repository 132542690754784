import { postApi } from "api/fetchWrapper";
import { newErrorNotification } from "ducks/notificationsDuck";
import { saveNow } from "ducks/autoSaveDuck";
import mapVm from "vm/mapVm";
import { removeUtkast } from "utils/localStorageUtils";
import { ExternalSkjemaClient } from "../../../../ApiClients";

const client = new ExternalSkjemaClient();

export const sendInnForm = (values, skjemaSvar, skjema, userMalform) => {
  return (dispatch, getState) => {
    saveNow()(dispatch)
      .then((e) => {
        const vm = mapVm(e.payload.eksternId, skjemaSvar, getState().initialSkjemaSvar, skjema, userMalform, values);
        postApi(client.submitSkjemaSvar(skjema.skjemaId, skjema.innsendingsKode, vm))
          .then((data) => {
            removeUtkast(skjema.skjemaId, e.payload.eksternId);
            window.location = data.url;
          })
          .catch((error) => {
            dispatch(newErrorNotification(error.title, error.message));
          });
      })
      .catch((error) => {
        dispatch(newErrorNotification(error.title, error.message));
      });
  };
};
