import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Input } from "@utdanningsdirektoratet/input";
import { Icon } from "@utdanningsdirektoratet/icon";
import { PopinFooter } from "@utdanningsdirektoratet/popin";
import { Form } from "@utdanningsdirektoratet/form";
import { Radio, RadioGroup } from "@utdanningsdirektoratet/radio";
import { skjemaShape } from "utils/PropTypes";
import { useIntl, FormattedMessage } from "react-intl";
import { enums, personvernerklaering } from "logic/initialState";
import { scrollTo } from "utils/scrollUtils";
import { Element } from "react-scroll";
import { ObligatoriskhetEnum, SektorEnum } from "constants/serverEnums";
import BekreftRepresentasjon from "./BekreftRepresentasjon";
import FieldName from "../FieldName";
import MalgruppeSektor from "./MalgruppeSektor";

const fieldsToReset = [
  FieldName.SEKTOR,
  FieldName.MALGRUPPE,
  FieldName.REPRESENTERER,
  FieldName.STILLING,
  FieldName.FYLKE,
  FieldName.KOMMUNE,
  FieldName.UNDERTYPE,
  FieldName.BEKREFTREPRESENTASJON,
];

const getHasMultipleMalgrupperOrNeedsMoreMetaData = (valgtMalgruppe, malgruppeEnkeltperson, malgruppeOffentligEllerPrivat) => {
  if (!valgtMalgruppe) return true;
  if (valgtMalgruppe.sektor === SektorEnum.EnkeltPerson) {
    return !(
      valgtMalgruppe.innsenderRepresentererObligatoriskhet === ObligatoriskhetEnum.IkkeEtterspor &&
      valgtMalgruppe.innsenderStillingObligatoriskhet === ObligatoriskhetEnum.IkkeEtterspor &&
      valgtMalgruppe.geografiObligatoriskhet === ObligatoriskhetEnum.IkkeEtterspor &&
      malgruppeEnkeltperson.length === 1
    );
  }
  return !(
    valgtMalgruppe.innsenderRepresentererObligatoriskhet === ObligatoriskhetEnum.IkkeEtterspor &&
    valgtMalgruppe.innsenderStillingObligatoriskhet === ObligatoriskhetEnum.IkkeEtterspor &&
    valgtMalgruppe.geografiObligatoriskhet === ObligatoriskhetEnum.IkkeEtterspor &&
    malgruppeOffentligEllerPrivat.length === 1
  );
};

const SendInnForm = ({
  fields,
  skjema,
  popinId,
  onSuccess,
  resetFields,
  validateFields,
  representererEnkeltperson,
  setRepresenterer,
  ...rest
}) => {
  const [onSubmitClicked, setOnSubmitClicked] = useState(false);
  const intl = useIntl();
  const { maBekreftes } = skjema;
  const { lenkeTilPersonvernerklaering } = skjema;
  const { malgrupper } = skjema;

  useEffect(() => {
    if (malgrupper.length === 1) {
      fields.sektor.onChange(malgrupper[0].sektor);
      fields.malgruppe.onChange(malgrupper[0].id);
    }
    if ([...new Set(malgrupper.map((m) => m.tittel))].length === 1) {
      fields.malgruppe.onChange(malgrupper[0].id);
    }
    if ([...new Set(malgrupper.map((m) => m.sektor))].length === 1) {
      fields.sektor.onChange(malgrupper[0].sektor);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const valgtMalgruppe = useMemo(
    () => malgrupper.find((m) => m.id === parseInt(fields.malgruppe.value, 10)),
    [fields.malgruppe.value, malgrupper]
  );
  const malgruppeEnkeltperson = useMemo(
    () => malgrupper.filter((malgruppe) => malgruppe.sektor === enums.sektor.enkeltperson),
    [malgrupper]
  );
  const malgruppeOffentligEllerPrivat = useMemo(
    () => malgrupper.filter((malgruppe) => malgruppe.sektor !== enums.sektor.enkeltperson),
    [malgrupper]
  );

  const buttonClass = classnames({
    "u--marginTop2": maBekreftes || (valgtMalgruppe && valgtMalgruppe.autoPubliser),
    "u--marginTop5": !maBekreftes && !(valgtMalgruppe && valgtMalgruppe.autoPubliser),
  });

  const hasMultipleMalgrupperOrNeedsMoreMetaData = useMemo(
    () => getHasMultipleMalgrupperOrNeedsMoreMetaData(valgtMalgruppe, malgruppeEnkeltperson, malgruppeOffentligEllerPrivat),
    [malgruppeEnkeltperson, malgruppeOffentligEllerPrivat, valgtMalgruppe]
  );

  const onValidationError = () => {
    if (fields[FieldName.NAVN].error) {
      scrollTo(FieldName.NAVN, { popinId });
    } else if (fields[FieldName.EPOST].error) {
      scrollTo(FieldName.EPOST, { popinId });
    } else if (fields[FieldName.SEKTOR].error) {
      scrollTo(FieldName.SEKTOR, { popinId });
    } else if (fields[FieldName.MALGRUPPE].error) {
      scrollTo(FieldName.MALGRUPPE, { popinId });
    } else if (fields[FieldName.REPRESENTERER].error) {
      scrollTo(FieldName.REPRESENTERER, { popinId });
    } else if (fields[FieldName.FYLKE].error) {
      scrollTo(FieldName.FYLKE, { popinId });
    } else if (fields[FieldName.KOMMUNE].error) {
      scrollTo(FieldName.KOMMUNE, { popinId });
    } else if (fields[FieldName.UNDERTYPE].error) {
      scrollTo(FieldName.UNDERTYPE, { popinId });
    } else if (fields[FieldName.STILLING].error) {
      scrollTo(FieldName.STILLING, { popinId });
    } else if (fields[FieldName.BEKREFTREPRESENTASJON].error) {
      scrollTo(FieldName.BEKREFTREPRESENTASJON, { popinId });
    }
    setOnSubmitClicked(false);
  };

  return (
    <Form {...rest} onSubmit={onSuccess} onError={onValidationError} validateFields={validateFields}>
      {({ onSubmit }) => {
        const onSubmitClick = () => {
          setOnSubmitClicked(true);
          onSubmit();
        };
        return (
          <>
            <div>
              <div className="Grid-12-12">
                <Element name={FieldName.NAVN} />
                <label className="SendInnPopin-label" htmlFor="mittNavn">
                  {intl.formatMessage({ id: "sendInnPopin.mittNavn" })}
                </label>
                <Input
                  aria-label={intl.formatMessage({ id: "sendInnPopin.mittNavn" })}
                  {...fields.navn}
                  placeholder=""
                  id="mittNavn"
                  className="SendInnPopin-input"
                />
              </div>

              <Element name={FieldName.EPOST} />
              <div className="Grid-12-12">
                <label className="SendInnPopin-label" htmlFor="minEpost">
                  {intl.formatMessage({ id: "sendInnPopin.minEpost" })}
                </label>
                <Input
                  aria-label={intl.formatMessage({ id: "sendInnPopin.minEpost" })}
                  {...fields.epost}
                  placeholder=""
                  id="minEpost"
                  className="SendInnPopin-input"
                  autoComplete="email"
                  type="email"
                />
              </div>
              {malgruppeEnkeltperson.length > 0 && malgruppeOffentligEllerPrivat.length > 0 ? (
                <>
                  <div className="SendInnPopin-label" htmlFor="representerer">
                    <FormattedMessage id="sendInnPopin.hvemSender" />
                  </div>
                  <Element name={FieldName.SEKTOR} />
                  <RadioGroup error={fields.sektor.error} className="SendInnPopin-radioWrapper" label="Sektor valg" name="group">
                    <Radio
                      error={fields.sektor.error}
                      boxed
                      checked={representererEnkeltperson !== undefined && representererEnkeltperson}
                      label={intl.formatMessage(
                        { id: "sendInnPopin.jegSenderPaaVegneAv" },
                        { JegRepresenterer: intl.formatMessage({ id: "sendInnPopin.megSelv" }) }
                      )}
                      className="SendInnPopin-radiobuton"
                      onChange={() => {
                        if (representererEnkeltperson === undefined || !representererEnkeltperson) {
                          resetFields(fieldsToReset);
                          setRepresenterer(true);
                          fields.sektor.onChange(enums.sektor.enkeltperson);
                          if (malgruppeEnkeltperson.length === 1) {
                            fields.malgruppe.onChange(malgruppeEnkeltperson[0].id);
                          }
                        }
                      }}
                    >
                      {hasMultipleMalgrupperOrNeedsMoreMetaData ? (
                        <MalgruppeSektor
                          fields={fields}
                          valgtMalgruppe={valgtMalgruppe}
                          malgrupper={malgrupper}
                          resetFields={resetFields}
                        />
                      ) : null}
                    </Radio>
                    <Radio
                      error={fields.sektor.error}
                      boxed
                      checked={representererEnkeltperson !== undefined && !representererEnkeltperson}
                      label={intl.formatMessage(
                        { id: "sendInnPopin.jegSenderPaaVegneAv" },
                        { JegRepresenterer: intl.formatMessage({ id: "sendInnPopin.minArbeidsgiver" }) }
                      )}
                      onChange={() => {
                        if (representererEnkeltperson === undefined || representererEnkeltperson) {
                          setRepresenterer(false);
                          resetFields(fieldsToReset);
                          if ([...new Set(malgruppeOffentligEllerPrivat.map((m) => m.sektor))].length === 1) {
                            fields.sektor.onChange(malgruppeOffentligEllerPrivat[0].sektor);
                          }
                          if ([...new Set(malgruppeOffentligEllerPrivat.map((m) => m.tittel))].length === 1) {
                            fields.malgruppe.onChange(malgruppeOffentligEllerPrivat[0].id);
                          }
                          if (malgruppeOffentligEllerPrivat.length === 1) {
                            fields.malgruppe.onChange(malgruppeOffentligEllerPrivat[0].id);
                          }
                        }
                      }}
                    >
                      <Element name={FieldName.SEKTOR} />
                      {hasMultipleMalgrupperOrNeedsMoreMetaData ? (
                        <MalgruppeSektor
                          fields={fields}
                          valgtMalgruppe={valgtMalgruppe}
                          malgrupper={malgrupper}
                          resetFields={resetFields}
                        />
                      ) : null}
                    </Radio>
                  </RadioGroup>
                </>
              ) : (
                <MalgruppeSektor fields={fields} valgtMalgruppe={valgtMalgruppe} malgrupper={malgrupper} resetFields={resetFields} />
              )}

              {valgtMalgruppe && fields.sektor.value ? (
                <BekreftRepresentasjon
                  className={malgruppeEnkeltperson.length > 0 && malgruppeOffentligEllerPrivat.length > 0 ? null : "u--marginTop1"}
                  fields={fields}
                  valgtMalgruppe={valgtMalgruppe}
                />
              ) : null}

              <div>
                {(!skjema.skjult && valgtMalgruppe && valgtMalgruppe.autoPubliser) ||
                (lenkeTilPersonvernerklaering && personvernerklaering) ? (
                  <div className="SendInnPopin-message">
                    <Icon icon="circle info" type="small" placement="before" />
                    {!skjema.skjult && valgtMalgruppe && valgtMalgruppe.autoPubliser ? (
                      <>
                        <FormattedMessage id="sendInnPopin.automatiskPublisering" />
                        <br />
                      </>
                    ) : null}
                    {lenkeTilPersonvernerklaering && personvernerklaering ? (
                      <>
                        <FormattedMessage id="sendInnPopin.lenkeTilPersonvernErklaeringText" />
                        <br />
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a
                          href={personvernerklaering.url}
                          target={personvernerklaering.blank ? "_blank" : null}
                          rel={personvernerklaering.blank ? "noopener noreferrer" : null}
                        >
                          {personvernerklaering.tekst}
                        </a>
                      </>
                    ) : null}
                  </div>
                ) : null}

                {maBekreftes ? (
                  <div className="SendInnPopin-message">
                    <Icon icon="email" type="small" placement="before" />
                    <FormattedMessage id="sendInnPopin.duVilMottaBekreftelsesEpost" />
                  </div>
                ) : null}
              </div>
            </div>
            <PopinFooter
              className={buttonClass}
              popinId={popinId}
              onSave={onSubmitClick}
              saveButtonProps={{
                disabled: onSubmitClicked,
              }}
              cancelButton={<FormattedMessage id="formLabels.avbryt" />}
              saveButton={<FormattedMessage id="formLabels.sendInn" />}
            />
          </>
        );
      }}
    </Form>
  );
};

SendInnForm.propTypes = {
  fields: PropTypes.object.isRequired,
  skjema: skjemaShape.isRequired,
  popinId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  resetFields: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
  representererEnkeltperson: PropTypes.bool,
  setRepresenterer: PropTypes.func.isRequired,
};

export default SendInnForm;
