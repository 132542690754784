export const ObligatoriskhetEnum = {
  Obligatorisk: "Obligatorisk",
  Valgfritt: "Valgfritt",
  IkkeEtterspor: "IkkeEtterspør",
};

export const GeografiObligatoriskhetEnum = {
  IkkeEtterspor: "IkkeEtterspør",
  FylkeObligatorisk: "FylkeObligatorisk",
  FylkeOgKommuneObligatorisk: "FylkeOgKommuneObligatorisk",
};

export const SektorEnum = {
  Offentlig: "Offentlig",
  Privat: "Privat",
  EnkeltPerson: "Enkeltperson",
};

export const JaNeiSvarEnum = {
  Ja: "Ja",
  Nei: "Nei",
  VetIkke: "VetIkke",
  IkkeAngitt: "IkkeAngitt",
};

export const EnigUenigSvarEnum = {
  IkkeAngitt: "IkkeAngitt",
  HeltUenig: "HeltUenig",
  DelvisUenig: "DelvisUenig",
  HverkenEller: "HverkenEller",
  DelvisEnig: "DelvisEnig",
  HeltEnig: "HeltEnig",
};

export const PositivNegativEnum = {
  Positiv: "Positiv",
  Negativ: "Negativ",
  HverkenEller: "HverkenEller",
  IkkeAngitt: "IkkeAngitt",
};

export const InnsendtStatusEnum = {
  IkkeInnsendt: "IkkeInnsendt",
  UnderInnsending: "UnderInnsending",
  Innsendt: "Innsendt",
};

export const BekreftetStatus = {
  IkkeBekreftet: "IkkeBekreftet",
  AvInnsender: "AvInnsender",
  AvSaksBehandler: "AvSaksBehandler",
  LukketInnsending: "LukketInnsending",
  TrengerIkkeBekreftes: "TrengerIkkeBekreftes",
};

export const Malform = {
  Bokmal: "Bokmål",
  Nynorsk: "Nynorsk",
};

export const PubliseringsStatus = {
  IkkePublisert: "IkkePublisert",
  PublisertApen: "PublisertÅpen",
  PublisertLukket: "PublisertLukket",
};

export const KortSvarTypes = {
  Tekst: "Tekst",
  Epost: "Epost",
  Orgnr: "Orgnr",
  Kontonr: "Kontonr",
};
