import PropTypes from "prop-types";
import { RichTextView, ConfigBuilder } from "@utdanningsdirektoratet/richtext";
import classnames from "classnames";
import { Element } from "react-scroll";
import { isEmpty } from "utils/stringUtils";
import { seksjonShape } from "utils/PropTypes";
import { scrollToId, ScrollType } from "utils/scrollUtils";

import { useSelector } from "react-redux";
import SporsmalSvar from "./SporsmalSvar";
import MarkerKommentar from "./components/markerKommentar";
import { getTextWithLocale } from "../../../../utils/localeUtils";

const SeksjonSvar = ({ seksjons, updateSeksjonSvar, skjulNummerering, ...rest }) => {
  const locale = useSelector((state) => state.locale);
  const body = seksjons.map((seksjon) => {
    const { tittel, brodtekst, kanHaMarkering, nummerering, id, niva } = seksjon;

    const malformTittel = getTextWithLocale(tittel, locale);
    const malformBrodtekst = getTextWithLocale(brodtekst, locale);

    const pageClass = classnames({
      "Page-section": true,
      "Page-section--empty": isEmpty(malformBrodtekst),
    });

    const titleClass = classnames({
      "u-h1": niva === 0,
      "u-h2": niva === 1,
      "u-h3": niva > 1,
      "Seksjon-title": true,
    });

    const TitleTag = `h${Math.min(niva + 2, 6)}`;

    const sporsmalElements = seksjon.sporsmals.map((sporsmal) => {
      return <SporsmalSvar key={`SporsmalSvar-${sporsmal.id}`} sporsmal={sporsmal} {...rest} />;
    });

    const config = new ConfigBuilder().withImages().withAllowedTags("span").build();
    const html = malformBrodtekst.replace(/<table/gi, '<span class="table-wrapper"><table').replace(/<\/table>/gi, "</table></span>");

    return (
      <div key={`seksjonsvar-${id}`} className="Seksjon">
        <Element name={scrollToId(ScrollType.SEKSJON, id)} />
        <div className={pageClass}>
          <div id={nummerering.replace(/\.$/gi, "")}>
            <TitleTag id={`seksjon-${id}`} className={titleClass}>
              {skjulNummerering ? malformTittel : `${nummerering} ${malformTittel}`}
            </TitleTag>
          </div>
          {kanHaMarkering ? (
            <MarkerKommentar id={id} updateSeksjonSvar={updateSeksjonSvar} {...rest}>
              <RichTextView html={html} config={config} />
            </MarkerKommentar>
          ) : (
            <div className="u--marginBottom2">
              <RichTextView html={html} config={config} />
            </div>
          )}
        </div>
        <div>{sporsmalElements}</div>
      </div>
    );
  });

  return <div>{body}</div>;
};

SeksjonSvar.propTypes = {
  seksjons: PropTypes.arrayOf(seksjonShape).isRequired,
  updateSeksjonSvar: PropTypes.func.isRequired,
  skjulNummerering: PropTypes.bool,
};

export default SeksjonSvar;
