import { getDefaultLocaleFromMalforms } from "../utils/localeUtils";

const flatten = (arr) => arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatten(val) : val), []);

const initial = window.initialState;
export const urls = window.skjemaUrls;
export const froala = window.froalaLicense;
export const sentry = window.sentryId;
export const { texts, footer, personvernerklaering, institusjonLongName, enums, fylker } = initial;

const getSporsmalFromSkjema = (skjema) => flatten(skjema.underskjema.map((n) => n.seksjons.map((s) => s.sporsmals)));
const getSeksjonerFromSkjema = (skjema) => flatten(skjema.underskjema.map((n) => n.seksjons));
const getUnderskjemaFromSkjema = (skjema) => flatten(skjema.underskjema);

const getSporsmalSvars = (sporsmals) =>
  sporsmals.reduce((sporsmalSvars, sporsmal) => {
    const returnVal = {};
    if (sporsmal.positivNegativ) {
      returnVal.positivNegativSvar = { value: sporsmal.positivNegativ.positivNegativString };
    }
    if (sporsmal.jaNei) {
      returnVal.jaNeiSvar = { value: sporsmal.jaNei.jaNeiString };
    }
    if (sporsmal.enigUenig) {
      returnVal.enigUenigSvar = { value: sporsmal.enigUenig.enigUenigString };
    }
    if (sporsmal.heltall) {
      returnVal.heltallSvar = { value: sporsmal.heltall.heltallInt };
    }
    if (sporsmal.kortTekst) {
      returnVal.kortTekstSvar = { value: sporsmal.kortTekst.kortTekstString || "" };
    }
    if (sporsmal.dato) {
      returnVal.datoSvar = { value: sporsmal.dato.dato, dates: {} };
    }
    if (sporsmal.fylkeKommune) {
      returnVal.fylkeKommuneSvar = { value: sporsmal.fylkeKommune.fylkeKommuneStrings ?? [] };
    }
    if (sporsmal.kommentar) {
      returnVal.kommentar = { value: sporsmal.kommentar.kommentarString || "" };
    }
    if (sporsmal.vedlegg) {
      returnVal.vedlegg = sporsmal.vedlegg.vedlegg;
    }
    let egendefinerteSvar = [];
    const getEgendefinerte = (alts) =>
      alts.filter((alt) => alt.erEgendefinert && alt.tittel).map((alt) => ({ id: alt.id, tittel: alt.egendefinertSvar ?? "" }));
    if (sporsmal.ettValg) {
      returnVal.ettValgId = {
        value: sporsmal.ettValg.svarAlternativ
          .filter((alt) => alt.selected)
          .map((alt) => alt.id)
          .find(() => true),
      };
      egendefinerteSvar = egendefinerteSvar.concat(getEgendefinerte(sporsmal.ettValg.svarAlternativ));
    }
    if (sporsmal.flereValg) {
      returnVal.flereValgIds = sporsmal.flereValg.svarAlternativ.filter((alt) => alt.selected).map((alt) => alt.id);
      egendefinerteSvar = egendefinerteSvar.concat(getEgendefinerte(sporsmal.flereValg.svarAlternativ));
    }
    if (egendefinerteSvar.length > 0) returnVal.egendefinertSvar = egendefinerteSvar.reduce((agg, v) => ({ ...agg, [v.id]: v.tittel }), {});
    return { ...sporsmalSvars, [sporsmal.id]: returnVal };
  }, {});

const getSeksjonerSvars = (seksjoner) =>
  seksjoner.reduce((seksjonSvars, seksjon) => {
    const returnVal = {};
    if (seksjon.kanHaMarkering) {
      returnVal.markeringKommentarer = seksjon.markeringKommentarer;
    }
    return { ...seksjonSvars, [seksjon.id]: returnVal };
  }, {});

const getUnderskjemaSvars = (underskjemaList) =>
  underskjemaList.reduce((underskjemaSvars, underskjema) => {
    const returnVal = {};
    if (underskjema.kanHaGenerellKommentar) {
      returnVal.kommentar = { value: underskjema.underskjemaSvar.generellKommentar || "" };
    }
    if (underskjema.kanHaGenerellHoldning) {
      returnVal.positivNegativSvar = { value: underskjema.underskjemaSvar.generellHoldning };
    }
    return { ...underskjemaSvars, [underskjema.id]: returnVal };
  }, {});

const getNotifications = ({ notifications }) => {
  if (notifications.length === 0) return undefined;

  return {
    nextId: notifications.length,
    notifications,
  };
};

const getDefaultLocale = (skjema) => {
  if (initial.skjema.malforms.includes(",")) {
    return skjema.userMålform ?? getDefaultLocaleFromMalforms(initial.skjema.malforms);
  }
  return initial.skjema.malforms;
};

const transform = () => {
  if (!initial.skjema) return { ...initial };

  const initialSkjemaSvar = {
    ingenMerknader: initial.skjema.ingenMerknader.ingenMerknader,
    sporsmal: getSporsmalSvars(getSporsmalFromSkjema(initial.skjema)),
    seksjoner: getSeksjonerSvars(getSeksjonerFromSkjema(initial.skjema)),
    underskjema: getUnderskjemaSvars(getUnderskjemaFromSkjema(initial.skjema)),
  };

  return {
    ...initial,
    notifications: getNotifications(initial.skjema),
    skjemaSvar: initialSkjemaSvar,
    initialSkjemaSvar,
    sporsmal: getSporsmalFromSkjema(initial.skjema).reduce(
      (sporsmals, sporsmal) => ({
        ...sporsmals,
        [sporsmal.id]: sporsmal,
      }),
      {}
    ),
    seksjon: getSeksjonerFromSkjema(initial.skjema).reduce(
      (seksjons, seksjon) => ({
        ...seksjons,
        [seksjon.id]: seksjon,
      }),
      {}
    ),
    locale: getDefaultLocale(initial.skjema),
    underskjema: getUnderskjemaFromSkjema(initial.skjema).reduce(
      (underskjemaList, underskjema) => ({ ...underskjemaList, [underskjema.id]: underskjema }),
      {}
    ),
  };
};

export default transform;
