import { scroller } from 'react-scroll';

export const ScrollType = {
  UNDERSKJEMA: 'underskjema',
  SEKSJON: 'seksjon',
  SPORSMAL: 'sporsmal'
};

export const scrollToId = (type, id) => `${type}-${id}`;

const defaultOptions = {
  smooth: true,
  duration: 250
};

export const scrollToTop = (options) => {
  scroller.scrollToTop({ ...defaultOptions, ...options });
};

export const scrollToBottom = (options) => {
  scroller.scrollToBottom({ ...defaultOptions, ...options });
};

// I options er det en property som heter containerId, denne kan settes hvis en annen scroll enn body skal scrolles.
// Med andre ord om det er en scrollpane inne i en annen scrollpane kan denne verdien endre hvilken av disse to pane'sa som blir targetet.
export const scrollTo = (name, options) => scroller.scrollTo(name, { ...defaultOptions, ...options });
