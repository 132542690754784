import PropTypes from "prop-types";
import { InpageMessage } from "@utdanningsdirektoratet/inpagemessage";
import { FormattedMessage, useIntl } from "react-intl";
import { PubliseringsStatus } from "constants/serverEnums";
import { skjemaShape } from "utils/PropTypes";

const MessageBanner = ({ skjema }) => {
  const intl = useIntl();
  const { innsendt, publiseringsStatus, utgatt, lukketMenKanSendesInn, forhandsvisning } = skjema;

  let message;
  if (lukketMenKanSendesInn) {
    message = <FormattedMessage id="svar.lukketMenKanSendesInn" />;
  } else if (!innsendt && publiseringsStatus === PubliseringsStatus.IkkePublisert && !forhandsvisning) {
    message = <FormattedMessage id="svar.detteSkjemaErIkkePublisert" />;
  } else if ((!innsendt && publiseringsStatus === PubliseringsStatus.PublisertLukket) || forhandsvisning) {
    message = <FormattedMessage id="svar.ikkeApenForSvar" />;
  } else if (!innsendt && publiseringsStatus === PubliseringsStatus.PublisertApen && utgatt) {
    message = <FormattedMessage id="svar.fristUtlopt" />;
  }

  if (!message) return null;

  return <InpageMessage type="warning" message={message} inline className="u--marginBottom1" />;
};

MessageBanner.propTypes = {
  skjema: skjemaShape,
};

export default MessageBanner;
