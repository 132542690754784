import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Button } from "@utdanningsdirektoratet/button";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { PositivNegativEnum } from "constants/serverEnums";
import { validationErrorsShape } from "utils/PropTypes";
import classnames from "classnames";
import Obligatorisk from "./Obligatorisk";

const PositivNegativSvar = ({
  readonly,
  obligatorisk,
  forhandsvisning,
  id,
  updateSvar,
  positivNegativSvar,
  validationErrors,
  generell,
}) => {
  const intl = useIntl();
  let positiv = "formLabels.positiv";
  let negativ = "formLabels.negativ";
  if (generell) {
    positiv = "formLabels.genereltPositiv";
    negativ = "formLabels.genereltNegativ";
  }

  if (!forhandsvisning && readonly) {
    let svar = positivNegativSvar.value;
    if (svar === PositivNegativEnum.IkkeAngitt) return null;
    if (svar === PositivNegativEnum.HverkenEller) svar = <FormattedMessage id="formLabels.hverkenEller" />;
    if (svar === PositivNegativEnum.Positiv) {
      svar = (
        <span>
          <Icon icon="plus" type="small" placement="before" />
          <FormattedMessage id={positiv} />
        </span>
      );
    }
    if (svar === PositivNegativEnum.Negativ) {
      svar = (
        <span>
          <Icon icon="minus" type="small" placement="before" />
          <FormattedMessage id={negativ} />
        </span>
      );
    }
    return <div className="Sporsmal-body Sporsmal-body--bold">{svar}</div>;
  }
  const error = validationErrors.length > 0;
  const buttonClass = classnames({
    "Sporsmal-button": true,
    "Sporsmal-button--error": error,
  });
  const button = (val, txt) => {
    const active = positivNegativSvar.value === val;
    return (
      <Button
        className={buttonClass}
        active={active}
        disabled={readonly}
        onClick={() =>
          updateSvar(id, {
            positivNegativSvar: { value: active ? PositivNegativEnum.IkkeAngitt : val },
          })
        }
      >
        {txt}
      </Button>
    );
  };

  const validationErrorMessagesMalform = validationErrors.map((err) => intl.formatMessage(err.text)).join("\n");

  return (
    <div className="Sporsmal-body">
      <Tooltip className="u--block" message={validationErrorMessagesMalform} position="top" align="left" disabled={!error} alwaysShow>
        <Obligatorisk isObligatorisk={obligatorisk}>
          {button(PositivNegativEnum.Positiv, <FormattedMessage id={positiv} />)}
          {button(PositivNegativEnum.Negativ, <FormattedMessage id={negativ} />)}
          {button(PositivNegativEnum.HverkenEller, <FormattedMessage id="formLabels.hverkenEller" />)}
        </Obligatorisk>
      </Tooltip>
    </div>
  );
};

PositivNegativSvar.propTypes = {
  id: PropTypes.number.isRequired,
  updateSvar: PropTypes.func.isRequired,
  positivNegativSvar: PropTypes.shape({
    value: PropTypes.oneOf([
      PositivNegativEnum.Positiv,
      PositivNegativEnum.Negativ,
      PositivNegativEnum.HverkenEller,
      PositivNegativEnum.IkkeAngitt,
    ]),
  }).isRequired,
  readonly: PropTypes.bool.isRequired,
  obligatorisk: PropTypes.bool,
  generell: PropTypes.bool,
  forhandsvisning: PropTypes.bool.isRequired,
  validationErrors: validationErrorsShape.isRequired,
};

export default PositivNegativSvar;
