import { PureComponent } from "react";
import PropTypes from "prop-types";
import { getUtkasts, removeUtkast, addOrUpdateUtkast } from "utils/localStorageUtils";
import { PubliseringsStatus } from "constants/serverEnums";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { show } from "@utdanningsdirektoratet/popin";
import { logError } from "@utdanningsdirektoratet/utils/logger";
import { skjemaShape } from "utils/PropTypes";
import ReactRouterPropTypes from "react-router-prop-types";
import { scrollTo } from "utils/scrollUtils";
import {
  removeScrollTo as removeScrollToAction,
  removeReset as removeResetAction,
  navigateToUtkast as navigateToUtkastAction,
  removeInnsendingsKode as removeInnsendingsKodeAction,
} from "ducks/navigationDuck";
import { injectIntl } from "react-intl";
import UtkastPopin, { utkastPopinId } from "./components/utkastPopin/UtkastPopin";
import SkjemaSvar from "./SkjemaSvar";
import { newSuccessNotification } from "../../ducks/notificationsDuck";
import { Header } from "./components/header/header";

const emptyGuid = "00000000-0000-0000-0000-000000000000";

class Svar extends PureComponent {
  componentDidMount() {
    const { intl, skjema } = this.props;
    const { skjemaId, publiseringsStatus, eksternId, endret, innsendt, isBekreftet } = skjema;

    if (isBekreftet) {
      setTimeout(() => {
        newSuccessNotification(
          intl.formatMessage({ id: "svar.svarBekreftet" }),
          intl.formatMessage({ id: "svar.svarVilBliBehandlet" })
        );
      }, 0);
    }

    const search = new URLSearchParams(this.props.location.search);

    if (!search.get("reset")) {
      if (eksternId === emptyGuid) {
        if (publiseringsStatus === PubliseringsStatus.PublisertApen) {
          const utkasts = getUtkasts(skjemaId);
          if (utkasts.length >= 2) {
            setTimeout(() => show(utkastPopinId), 0);
          }
          if (utkasts.length === 1) {
            this.props.navigateToUtkast(utkasts[0].eksternId);
          }
        }
      } else if (innsendt) {
        removeUtkast(skjemaId, eksternId);
      } else {
        addOrUpdateUtkast(skjemaId, { eksternId, endret });
      }
    } else {
      this.props.removeReset();
    }

    if (search.get("innsendingsKode") === "null") {
      this.props.removeInnsendingsKode();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.underskjemaId !== this.props.underskjemaId || this.props.scrollTo || (this.props.scrollToTop && !prevProps.scrollTo)) {
      if (this.props.scrollTo) {
        scrollTo(this.props.scrollTo);
        this.props.removeScrollTo();
      } else if (
        prevProps.location.hash !== this.props.location.hash &&
        this.props.location.hash !== "" &&
        this.props.location.hash !== "#"
      ) {
        scrollTo(this.props.location.hash.replace(/#/, ""));
      } else {
        this.scrollToHeader();
      }
    }
  }

  componentDidCatch(error, info) {
    logError(error, info);
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToHeader = () => {
    scrollTo("headerScroll", {
      offset: -40,
    });
  };

  render() {
    const { underskjemaId, location, skjema } = this.props;

    return (
      <>
        <Header />
        <SkjemaSvar underskjemaParam={underskjemaId} location={location} />
        <UtkastPopin skjemaId={skjema.skjemaId} />
      </>
    );
  }
}

Svar.propTypes = {
  skjema: skjemaShape.isRequired,
  underskjemaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scrollTo: PropTypes.string,
  scrollToTop: PropTypes.bool,
  location: ReactRouterPropTypes.location.isRequired,
  removeScrollTo: PropTypes.func.isRequired,
  navigateToUtkast: PropTypes.func.isRequired,
  removeReset: PropTypes.func.isRequired,
  removeInnsendingsKode: PropTypes.func.isRequired,
  intl: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    skjema: state.skjema,
    underskjemaId: state.navigation.underskjemaId,
    scrollTo: state.navigation.scrollTo,
    scrollToTop: state.navigation.scrollToTop,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeScrollTo: bindActionCreators(removeScrollToAction, dispatch),
    navigateToUtkast: bindActionCreators(navigateToUtkastAction, dispatch),
    removeReset: bindActionCreators(removeResetAction, dispatch),
    removeInnsendingsKode: bindActionCreators(removeInnsendingsKodeAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Svar));
