import mapSporsmalSvarVm from "./mapSporsmalSvarVm";
import mapSeksjonSvarVm from "./mapSeksjonSvarVm";
import mapUnderskjemaSvarVm from "./mapUnderskjemaSvarVm";

export default (eksternId, skjemaSvar, initialSkjemaSvar, skjema, userMalform, values) => {
  const sporsmalSvarVm = mapSporsmalSvarVm(skjemaSvar.sporsmal, initialSkjemaSvar.sporsmal);
  const seksjonSvarVm = mapSeksjonSvarVm(skjemaSvar.seksjoner);
  const UnderskjemaSvarVm = mapUnderskjemaSvarVm(skjemaSvar.underskjema, initialSkjemaSvar.underskjema);

  const vm = {
    eksternId,
    innsendingsKode: skjema.innsendingsKode,
    ingenMerknader: skjemaSvar.ingenMerknader,
    spørsmalSvar: sporsmalSvarVm,
    seksjonSvar: seksjonSvarVm,
    UnderskjemaSvar: UnderskjemaSvarVm,
    userMålform: userMalform,
  };

  if (values) {
    return {
      ...vm,
      innsender: {
        navn: values.navn,
        epost: values.epost,
        malgruppe: values.malgruppe,
        representerer: values.representerer,
        stilling: values.stilling,
        fylke: values.fylke,
        kommune: values.kommune,
        userMålform: values.userMålform,
        undertype: Object.keys(values.undertype).filter((key) => values.undertype[key]),
      },
    };
  }

  return vm;
};
