import { isUnderskjemaBesvart } from "logic/validation";
import { getKommentar } from "./vmUtils";

export default (UnderskjemaSvar, initial) =>
  Object.keys(UnderskjemaSvar)
    .map((id) => ({ id, utt: UnderskjemaSvar[id], initialUtt: initial[id].utt }))
    .filter((u) => isUnderskjemaBesvart(u.utt) || !isUnderskjemaBesvart(u.initialUtt))
    .map((u) => ({
      id: u.id,
      generellHoldning: u.utt.positivNegativSvar ? u.utt.positivNegativSvar.value : undefined,
      generellKommentar: getKommentar(u.utt, u.initialUtt),
    }));
