import PropTypes from "prop-types";
import classnames from "classnames";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { useIntl, FormattedMessage } from "react-intl";
import { validationErrorsShape } from "utils/PropTypes";
import SvarAlternativ, { TYPE_CHECK } from "./SvarAlternativ";
import Obligatorisk from "./Obligatorisk";

const trimFalsyProperties = (obj) =>
  Object.keys(obj)
    .filter((key) => obj[key])
    .reduce((values, key) => {
      return { ...values, [key]: obj[key] };
    }, {});

const FlereValg = ({
  flereValg,
  sporsmalSvar,
  sporsmalId,
  updateSporsmalSvar,
  readonly,
  obligatorisk,
  forhandsvisning,
  validationErrors,
}) => {
  const { svarAlternativ } = flereValg;
  const intl = useIntl();

  const noSelection = !svarAlternativ.some((alt) => alt.selected);
  if (!forhandsvisning && readonly && noSelection) {
    return (
      <div className="Sporsmal-body Sporsmal-body--bold">
        <div>
          <FormattedMessage id="validation.velgEttEllerFlereAlternativ" />
        </div>
        <em className="u--italic">
          <FormattedMessage id="validation.ingenAlternativerValgt" />
        </em>
      </div>
    );
  }

  const isSelected = (id) => sporsmalSvar.flereValgIds.some((u) => u === id);

  const updateValue =
    (id) =>
    ({ selected, egendefinert }) => {
      const flereValgIds = svarAlternativ.filter((alt) => (alt.id === id ? selected : isSelected(alt.id))).map((alt) => alt.id);
      const egendefinertSvar = trimFalsyProperties({ ...sporsmalSvar.egendefinertSvar, [id]: egendefinert });
      return updateSporsmalSvar(sporsmalId, { flereValgIds, egendefinertSvar });
    };

  const checkboxes = svarAlternativ.map((alternativ) => {
    const { id } = alternativ;

    const isChecked = isSelected(id);
    const svar = sporsmalSvar && sporsmalSvar.egendefinertSvar && sporsmalSvar.egendefinertSvar[id];

    return (
      <SvarAlternativ
        key={id}
        id={id}
        type={TYPE_CHECK}
        egendefinertSvar={svar !== undefined ? svar : ""}
        obligatorisk={obligatorisk}
        alternativ={alternativ}
        svarAlternativ={svarAlternativ}
        errors={validationErrors}
        disabled={readonly}
        selected={isChecked}
        onChange={({ selected, egendefinert }) => updateValue(id)({ selected, egendefinert })}
      />
    );
  });

  const checkboxClass = classnames({
    "u--marginTop1": checkboxes.length > 1,
  });

  const validationErrorMessagesMalform = validationErrors.filter((e) => !e.alternativId).map((e) => intl.formatMessage(e.text));
  return (
    <div className="Sporsmal-body">
      <Tooltip
        className="u--block"
        message={validationErrorMessagesMalform}
        align="left"
        position="bottom"
        disabled={validationErrors.filter((e) => !e.alternativId).length === 0}
        alwaysShow
      >
        {checkboxes.length > 1 ? (
          <Obligatorisk isObligatorisk={obligatorisk}>
            <FormattedMessage id="validation.velgEttEllerFlereAlternativ" />
          </Obligatorisk>
        ) : null}
        <div className={checkboxClass}>{checkboxes}</div>
      </Tooltip>
    </div>
  );
};

FlereValg.propTypes = {
  flereValg: PropTypes.object.isRequired,
  sporsmalSvar: PropTypes.shape({
    flereValgIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    egendefinertSvar: PropTypes.object,
  }),
  sporsmalId: PropTypes.number.isRequired,
  updateSporsmalSvar: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  obligatorisk: PropTypes.bool,
  forhandsvisning: PropTypes.bool.isRequired,
  validationErrors: validationErrorsShape.isRequired,
};

export default FlereValg;
