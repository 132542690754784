import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, useFormValidation, validationFunctions } from "@utdanningsdirektoratet/form";
import { bindActionCreators } from "redux";
import { Input } from "@utdanningsdirektoratet/input";
import { TagsInput } from "@utdanningsdirektoratet/tagsinput";
import { Textarea } from "@utdanningsdirektoratet/textarea";
import { Radio } from "@utdanningsdirektoratet/radio";
import { Popin, PopinFooter, close } from "@utdanningsdirektoratet/popin";
import { FormattedMessage, useIntl } from "react-intl";
import { skjemaShape } from "utils/PropTypes";
import { epostValidation } from "logic/validation";
import * as serverActions from "./serverActions";

const Tilgang = {
  LESETILGANG: "LESETILGANG",
  SKRIVETILGANG: "SKRIVETILGANG",
};

const mapToVm = (values, innsendingsKode) => {
  return {
    ...values,
    mottakerEpost: values.mottakerEpost.map((m) => m.title),
    skrivetilgang: values.tilgang === Tilgang.SKRIVETILGANG,
    innsendingsKode,
  };
};

const VideresendUtkastPopin = (props) => {
  const { id, skjema, actions } = props;
  const intl = useIntl();

  const formValidation = useFormValidation({
    innsenderEpost: {
      value: "",
      validation: [
        {
          ...validationFunctions.required,
          message: intl.formatMessage({ id: "validation.epostErObligatorisk" }),
        },
        {
          ...epostValidation,
          message: intl.formatMessage(epostValidation.message),
        },
      ],
    },
    mottakerEpost: {
      value: [],
      validation: [{ ...validationFunctions.required, message: intl.formatMessage({ id: "validation.manglendeMottagereposter" }) }],
    },
    kommentar: { value: "" },
    tilgang: { value: Tilgang.LESETILGANG, validation: [validationFunctions.required] },
  });
  const { fields, values } = formValidation;
  const onSuccess = () => {
    actions.videresendUtkast(mapToVm(values, skjema.innsendingsKode), () => {
      close(id);
    });
  };

  return (
    <Popin id={id} title={<FormattedMessage id="videresendPopin.title" />} closeableByExternalEvents cancelButton={false}>
      <Form {...formValidation} onSubmit={onSuccess}>
        {({ onSubmit }) => {
          return (
            <div>
              <p>
                <FormattedMessage id="videresendPopin.body" />
              </p>
              <Input {...fields.innsenderEpost} type="text" label={<FormattedMessage id="videresendPopin.delUtkastInnsenderEpost" />} />
              <div className="u--marginTop1">
                <TagsInput
                  {...fields.mottakerEpost}
                  delimiters={[13, 32, 188, 9]}
                  validationRegex={epostValidation.regex}
                  label={<FormattedMessage id="videresendPopin.delUtkastMottagernesEposter" />}
                  tagmessage={<FormattedMessage id="validation.maVaereGyldigEpostadresse" />}
                  addOnBlur
                />
              </div>
              <div className="u--marginTop3">
                <Textarea label={<FormattedMessage id="videresendPopin.delUtkastKommentar" />} {...fields.kommentar} />
              </div>
              <div className="u--marginTop3">
                <Radio
                  label={<FormattedMessage id="videresendPopin.delUtkastLeseTilgang" />}
                  name="videresendtUtkast"
                  checked={fields.tilgang.value === Tilgang.LESETILGANG}
                  onChange={() => fields.tilgang.onChange(Tilgang.LESETILGANG)}
                  error={fields.tilgang.error}
                />
                <Radio
                  label={<FormattedMessage id="videresendPopin.delUtkastSkriveTilgang" />}
                  className="u--marginTop1"
                  name="videresendtUtkast"
                  checked={fields.tilgang.value === Tilgang.SKRIVETILGANG}
                  onChange={() => fields.tilgang.onChange(Tilgang.SKRIVETILGANG)}
                  error={fields.tilgang.error}
                />
              </div>
              <PopinFooter
                popinId={id}
                onSave={onSubmit}
                cancelButton={<FormattedMessage id="formLabels.avbryt" />}
                saveButton={<FormattedMessage id="formLabels.videresend" />}
              />
            </div>
          );
        }}
      </Form>
    </Popin>
  );
};

VideresendUtkastPopin.propTypes = {
  id: PropTypes.string.isRequired,
  skjema: skjemaShape.isRequired,
  skjemaSvar: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  eksternId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    skjema: state.skjema,
    skjemaSvar: state.skjemaSvar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(serverActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideresendUtkastPopin);
