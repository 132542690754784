import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ReactRouterPropTypes from "react-router-prop-types";
import { underskjemaShape } from "utils/PropTypes";
import { useSelector } from "react-redux";
import { getTextWithLocale } from "../../../../utils/localeUtils";

const UnderskjemaLink = ({ underskjema, location, ingenMerkander }) => {
  const search = new URLSearchParams(location.search);
  const locale = useSelector((state) => state.locale);
  search.set("underskjemaId", underskjema.id);
  search.delete("sporsmalId");
  return (
    <Link className="Sidebar-item-link" to={`?${search.toString()}`}>
      {getTextWithLocale(underskjema.menyTittel, locale)}
      {ingenMerkander ? (
        <div className="Sidebar-item-label">
          <FormattedMessage id="innsendtSvar.ingenMerknader" />
        </div>
      ) : null}
    </Link>
  );
};

UnderskjemaLink.propTypes = {
  underskjema: underskjemaShape.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  ingenMerkander: PropTypes.bool,
};

export default UnderskjemaLink;
